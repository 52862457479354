import { Component } from '@angular/core';
import { IconContractService } from './services/icon.service';
import { IconexService } from './services/iconex.service';
import { CookieService } from './services/cookie.service';
import { IconbetService } from './services/iconbet.service';
import { GameService } from './services/game.service';
import { NotificationService } from './services/notification.service';
import { NgbModal, ModalDismissReasons, NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';
//import * as $ from 'jquery';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'DAOplinko';
  public address: string = '';
  public userSeed: string = '';
  public balance: number;
  public oMain: any;
  public oGame: any;
  public _betAmount: number = 10;
  public multipliers: number[][];
  public odds: number[][] = [[4,10,1,10,8,10]];
  public sidebetMultipliers: number[][];
  public minBet: number = 1;
  public maxBet: number = 100;
  public loading: boolean = false;
  public autoPlay: boolean = false;
  public autoPlayGames: number = 10;
  public autoPlayCurrentGame: number = 0;
  public autoPlayGamesLost: number = 0;
  public autoPlayGamesEven: number = 0;
  public autoPlayGamesWon: number = 0;
  public autoStop: boolean = false;
  public autoStopBalance: number = 0;
  public sideBet: boolean=  false;
  public bucketSelect: number;
  public maxSideBet: number = 25;
  public sideBetAmount: number = 1;
  public sideBetMultiplier: number = 40.25;
  public sideBetWinAmount: number = 40;
  public sidebetBucket: number = 0;

  constructor(
    public iconService: IconContractService,
    public iconexService: IconexService,
    public gameService: GameService,
    public cookieService: CookieService,
    public iconbetService: IconbetService,
    private notifyService: NotificationService,
    private modalService: NgbModal) {
  }

  public async ngOnInit() {

    //Subscribe to user seed changes from iconbet cookie
    this.iconbetService.userSeedEvent.subscribe((userSeed: string) => this.userSeed = userSeed);
    this.multipliers = await this.gameService.getBucketMultipliers();
    this.odds = await this.gameService.getSidebetOdds();
    this.sidebetMultipliers = await this.gameService.getSidebetMultipliers();

    //subscribe to address changes from iconbet cookie
    this.iconbetService.addressEvent.subscribe(async (address: string) => {
      this.UpdateAddress(address);
    });

    //Get current cookie address
    if (this.iconbetService.address)
      this.UpdateAddress(this.iconbetService.address);

    this.WaitForLoadGame();

    setTimeout(() => this.CheckLogin(), 2000);
  }

  public ngAfterViewInit() {
    //resize the parent iframe to fit the game
    //this fixes the issue with mobile view
    var availHeight = screen.height;

    let iframe = parent.document.getElementById('iconbet-game-iframe') as HTMLIFrameElement
    iframe.style.setProperty("height", availHeight + 'px', "important");//iframe.contentWindow.document.body.scrollHeight + 'px';

    let minHeight = parent.document.getElementsByClassName("embed-responsive embed-responsive-3by2")[0] as HTMLDListElement;
    minHeight.style.setProperty("min-height", "", "important");

    let height = parent.document.getElementsByClassName("embed-responsive embed-responsive-3by2")[0] as HTMLDListElement;
    height.style.setProperty("height", iframe.contentWindow.document.body.scrollHeight + 225 + 'px', "important");

    let color = parent.document.getElementsByClassName("embed-responsive embed-responsive-3by2")[0] as HTMLDListElement;
    color.style.setProperty("background-color", "#00021c", "important");
  }

  public get betAmount(): number {
    return this._betAmount;
  }

  public set betAmount(newBetAmount: number) {
    var tmpBetAmount = parseInt(newBetAmount + '');
    if (!isNaN(tmpBetAmount)) {
      this._betAmount = tmpBetAmount;
      if (this.oGame) {
        this.oGame.modifyBonus(this._betAmount);
      }
    } else {
      this._betAmount = 0;
    }
  }

  async UpdateAddress(address: string) {
    this.address = address;
    console.log('Check cookie address : ' + this.cookieService.getCookie('wallet_address'));
    this.LoadBalance();
  }

  async LoadBalance() {
    this.balance = await this.iconService.getBalance(this.address);
    console.log(this.balance);
    this.oGame.setBalance(this.balance);
  }

  async WaitForLoadGame() {
    var checkGameLoaded = () => {
      console.log("Waiting for game to load");
      try {
        var ifrm = document.getElementById('game_frame');
        var frame = (<any>(<HTMLIFrameElement>ifrm).contentWindow);
        if (frame) {

          this.oMain = frame.s_oMain;
          this.oGame = frame.s_oGame;
          $ = frame.$;
        }
      }
      catch (ex) {
        console.log("Failed to access game frame : " + ex);
      }

      if (this.oMain && this.oGame && this.address) {
        this.GameLoaded();
      }
      else
        setTimeout(checkGameLoaded, 250);
    }

    setTimeout(checkGameLoaded, 250);
  }

  async GameLoaded() {

    this.oGame.updateMultipliers(this.multipliers[0]);
    this.oGame.modifyBonus(this.betAmount);

    this.loading = false;
    if (this.address) this.oGame.Enable();

    $(this.oMain).on("bet_placed", async (evt, iTotBet, iColToLaunch, game) => {
      await this.Bet(iColToLaunch);
    });

    $(this.oMain).on("save_score", (evt) => {
      console.log("save_score");
      if (!this.autoPlay) this.LoadBalance();
    });

    $(this.oMain).on("ball_collision", (evt, row) => {
      //console.log("ball_collision : " + row);
      if (row == 1 && this.autoPlay) {
        this.LoadBalance();
        if (this.autoStop && this.balance < this.autoStopBalance) {
          this.notifyService.showWarning("Minimum balance reached, stopping", "Auto-Stop Triggered");
          this.oGame.Enable();
          setTimeout(() => {
            this.autoPlayCurrentGame = 0;
            this.autoPlayGamesEven = 0;
            this.autoPlayGamesWon = 0;          
            this.autoPlayGamesLost = 0;
          }, 7000);
        } else if (this.autoPlayGames == 0 || this.autoPlayGames > this.autoPlayCurrentGame) {
          this.oGame.launch(this.autoPlayCurrentGame % 6);
        } else {
          this.notifyService.showSuccess("Total games completed, stopping", "Auto-Play Completed");
          this.oGame.Enable();
          setTimeout(() => {
            this.autoPlayCurrentGame = 0;
            this.autoPlayGamesEven = 0;
            this.autoPlayGamesWon = 0;          
            this.autoPlayGamesLost = 0;
          }, 7000);
        }
      }
    });
  }

  async CheckLogin() {
    if (this.address && this.address != '')
      return;

    if (window.location.host.indexOf('localhost') >= 0 || window.location.host.indexOf('web.app') >= 0) {
      this.loading = true;
      this.address = await this.iconexService.selectAddress();
      this.UpdateAddress(this.address);
    } else if (!this.cookieService.checkCookie() || !this.cookieService.getCookie('wallet_address')) {
      this.loading = true;
      console.log('Asking for user login');
      this.iconbetService.askForLogin();
    }
  }

  public getHalfBet(betAmount: number): number {
    var result: number = betAmount / 2;
    if (result < this.minBet)
      result = this.minBet;
    return result;
  }

  public getDoubleBet(betAmount: number): number {
    var myMaxBet = this.getMyMaxBet(betAmount);
    var result: number = betAmount * 2;
    if (result > myMaxBet)
      result = myMaxBet;
    return result;
  }

  public getMyMaxBet(betAmount: number): number {
    //var maxLevel = this.getMyMaxLevel(betAmount);
    var myMaxBet = this.maxBet;
    if (myMaxBet > this.balance - 1)
      myMaxBet = this.balance - 1;
    return myMaxBet;
  }

  private async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  public async Bet(iColToLaunch: number) {
    try {
      console.log("bet placed");
      
      this.betAmount = Number(this.betAmount);
      if (this.betAmount < this.minBet) this.betAmount = this.minBet;
      if (this.betAmount > this.getMyMaxBet(this.betAmount)) this.betAmount = this.getMyMaxBet(this.betAmount);      

      this.loading = true;
      this.balance -= Number(this.betAmount);
      if (this.sideBet) this.balance -= Number(this.sideBetAmount);

      this.oGame.setBalance(this.balance);

      var bucket = 0;

      this.autoPlayCurrentGame++;
      if (!this.sideBet)
        bucket = await this.gameService.Bet(this.address, Number(this.betAmount), 1, 0, 0);
      else
        bucket = await this.gameService.Bet(this.address, Number(this.betAmount) + Number(this.sideBetAmount), 1, Number(this.sideBetAmount), Number(this.sidebetBucket));

      if (this.sideBet && bucket == Number(this.sidebetBucket)) {
        setTimeout(() => this.notifyService.showSuccess("You have won the sidebet, congratulations", "Sidebet Won"), 7000);
      }

      setTimeout(() => {

        if (this.autoPlay) {
          if (this.multipliers[0][bucket] < 1)
            this.autoPlayGamesLost ++;
          else if (this.multipliers[0][bucket] < 2)
            this.autoPlayGamesEven ++;
          else 
            this.autoPlayGamesWon ++;   

          this.LoadBalance();
        }
      }, 7000);

      this.oGame._setEndCol = () => bucket;
      

      this.oGame.launch2()
      this.loading = false;
      if (this.autoPlay == false)
        setTimeout(() => this.oGame.Enable(), 1000);
      this.iconbetService.updateLeaderboard();

    }
    catch (ex) {
      this.autoPlayCurrentGame--;
      this.notifyService.showError(ex, 'Placing Bet Failed');
      this.loading = false;
      this.oGame.Enable();
      this.LoadBalance();
    }
  }

  public getBucketOdds(bucket: number): number {
    var sum = this.odds[0].reduce((a, b) => a + b, 0);
    return this.odds[0][bucket] / sum;
  }
}
