import { Injectable } from '@angular/core';
import IconService, { HttpProvider, IconBuilder, IconAmount, IconConverter } from 'icon-sdk-js';
import { async } from '@angular/core/testing';
import { IconContractService } from './icon.service';
import { IconexService } from './iconex.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GameService {

    constructor(
        public iconService: IconContractService,
        public iconexService: IconexService
    ) { }

    public async Bet(address: string, amount: number, setup: number, side_bet_amount: number, side_bet_bucket: number): Promise<number> {
        var params = {
            b_setup: this.iconService.toHex(setup),
            side_bet_amount: this.iconService.toHex(IconService.IconAmount.of(side_bet_amount, IconService.IconAmount.Unit.ICX).toLoop()),
            side_bet_bucket: this.iconService.toHex(side_bet_bucket)
        }

        var result = await this.iconexService.callTransaction(address, environment.score_address, 'bet', amount, params);
        if (result && result.result) {
            const txHash = result.result;
            var txResult = await this.iconService.getTransaction(txHash);
            if (txResult.failure && txResult.failure.message) {
                throw `${txResult.failure.message}. Click <a href='${environment.transaction_url}${txHash}' target='_blank'><u>here</u></a> to view details`;
            }
            
            var firstLog = txResult.eventLogs[0];

            if (firstLog.indexed[0].indexOf('LandingBucketResult') >=0) {
                return this.iconService.toInt(firstLog.indexed[1]);
            }
        }
        else {
            if (result && result.message)
                throw `Unable to place bet : ${result.message}`;
            else
                throw `Unable to place bet`;
        }
    }
    
    public async getBucketMultipliers(): Promise<number[][]> {
        var gamesResponse = await this.iconService.getScoreMethod(environment.score_address, 'get_bucket_multipliers', {});
        if (gamesResponse) {
            return JSON.parse(gamesResponse);
        }
        return null;
    }
    
    public async getSidebetMultipliers(): Promise<number[][]> {
        var gamesResponse = await this.iconService.getScoreMethod(environment.score_address, 'get_side_multipliers', {});
        if (gamesResponse) {
            return JSON.parse(gamesResponse);
        }
        return null;
    }    

    public async getSidebetOdds(): Promise<number[][]> {
        var gamesResponse = await this.iconService.getScoreMethod(environment.score_address, 'get_bucket_odds', {});
        if (gamesResponse) {
            return JSON.parse(gamesResponse);
        }
        return null;
    }
}