import { Injectable, EventEmitter } from '@angular/core';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class IconbetService {
  public addressEvent : EventEmitter<any> = new EventEmitter();
  public userSeedEvent : EventEmitter<any> = new EventEmitter();
  public address: string = '';
  public userSeed: string = '';

  constructor(private cookieService: CookieService) { 
    this.subscribeAddressChanges();
    this.subscribeSeedChanges();
  }

  private subscribeAddressChanges() {
    console.log('Subscribing to wallet cookie...');
    setInterval(() => {
      var tempAddress = this.cookieService.getCookie('wallet_address');
      if (tempAddress != this.address) {
        this.address = tempAddress;
        this.addressEvent.emit(this.address);
      }
    }, 500);
  }

  private subscribeSeedChanges() {
    console.log('Subscribing to seed cookie...');
    setInterval(() => {
      var tempUserSeed = this.cookieService.getCookie('lucky_phrase');
      if (tempUserSeed != this.userSeed) {
        this.userSeed = tempUserSeed;
        this.userSeedEvent.emit(this.userSeed);
      }
    }, 500);
  }

  public askForLogin() {
    window.parent.dispatchEvent(
      new CustomEvent('LOGIN_REQUEST')
    );  
  }

  public updateLeaderboard() {
    window.parent.dispatchEvent(
      new CustomEvent('UPDATE_LEADERBOARD')
    );  
  }
}